import styled from 'styled-components';

export const StyledText = styled.div`
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
`;

export const ErrorMessage = styled.p`
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */
  color: #ff4646;
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  margin-left: 5px;
  margin-bottom: 40px;
`;

export const InputSelectWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FormContainerHome = styled.div`

.tipoDocumento-dropdown {
  min-width: 350px !important;
  max-height: 300px !important;
  overflow: auto;
  text-align: left;
}

display: flex;
flex-direction: column;

@media (min-width: 768px) {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

& Input {
  margin-bottom: 18px;
}

&.tipoDocumento {
  display: flex;
}

&.tipoDocumento .tipoDocumento-select {
  width: 160px;
  padding-top: 18px;
  right: -2px;
}

&.tipoDocumento .tipoDocumento-option {
  max-height: 300px;
  overflow: auto;
}

&.tipoDocumento .tipoDocumento-select .rc-select-selector {
  border-top-right-radius: 2px solid var(--grayColor200);
  border-bottom-right-radius: 2px solid var(--grayColor200);
  border-top-left-radius: none;
  border-bottom-left-radius: none;
}

&.tipoDocumento .tipoDocumento-select .select-label {
}

&.tipoDocumento .tipoDocumento-input input {
  border-top-left-radius: 2px solid var(--grayColor200);
  border-bottom-left-radius: 2px solid var(--grayColor200);
  border-top-right-radius: none;
  border-bottom-right-radius: none;
}

.progress-inline {
  position: relative;
  top: 0px;
  display: inline-block;
  height: 20px;
}

.progress-inline > div > div {
  fill: white;
  height: 40px;
  width: 40px;
}

.button-wrap-valid > span {
  display: flex;
}
`;

export const FormContainer = styled.div`

  @media (min-width: 768px) {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  & Input {
    margin-bottom: 18px;
  }

  &.tipoDocumento {
    display: flex;
  }

  &.tipoDocumento .tipoDocumento-select {
    width: 160px;
    padding-top: 18px;
    right: -2px;
  }

  &.tipoDocumento .tipoDocumento-select .rc-select-selector {
    border-top-right-radius: 2px solid var(--grayColor200);
    border-bottom-right-radius: 2px solid var(--grayColor200);
    border-top-left-radius: none;
    border-bottom-left-radius: none;
  }

  &.tipoDocumento .tipoDocumento-select .select-label {
  }

  &.tipoDocumento .tipoDocumento-input input {
    border-top-left-radius: 2px solid var(--grayColor200);
    border-bottom-left-radius: 2px solid var(--grayColor200);
    border-top-right-radius: none;
    border-bottom-right-radius: none;
  }

  .progress-inline {
    position: relative;
    top: 0px;
    display: inline-block;
    height: 20px;
  }

  .progress-inline > div > div {
    fill: white;
    height: 40px;
    width: 40px;
  }

  .button-wrap-valid > span {
    display: flex;
  }
`;

export const Steps = styled.div`
  .current:before {
    border: 0.2rem solid var(--greenColor) !important;
    background-color: var(--whiteColorBase);
    color: var(--greenColor) !important;
  }
  .active:before {
    border: 0.2rem solid var(--greenColor) !important;
    background-color: var(--whiteColorBase);
    color: var(--greenColor) !important;
  }
`;

export const Content = styled.div`
  overflow-y: auto;
  background-color: white;
  order: 2;
  padding-bottom: 2rem;
  overflow-x: hidden;
  .fade-in {
    margin-top: 30px;
  }

  .recomended {
    border-radius: 16px;
    padding: 16px 32px;
    border: 1px solid #d6d6d6 !important;
  }

  .alertCustom {
    border-radius: 16px;
    padding: 16px 32px;
    background-color: #eefbf9 !important;
    box-shadow: inset 0 0 0 1px #eefbf9 !important;
    color: var(--greenColor) !important;
    img {
      margin-right: 19px;
    }
  }

  @media (min-width: 950px) {
    padding-top: 2rem;
  }
`;

export const Wrapper = styled.div`
  margin: 0;
  width: 100%;
  background: #fff;
  @media (min-width: 950px) {
    height: 100vh;
    display: grid;
    gap: 0px;
    grid-template-columns: 445px 1fr;
  }
`;

export const NavStyledHome = styled.div`
    height: 64px;
    margin-bottom: 2rem;
    margin-left:  -1rem;
    margin-right:  -1rem;
    padding-left:  1rem;
    padding-right:  1rem;
    display: none;
    @media (min-width: 950px) {
        box-shadow: none;
        margin-left:  inherit;
        margin-right:  inherit;
        padding-left:  0;
        padding-right:  0;
        display: block;
    }
`;

export const TextStyled = styled.div`
    color: var(--orangeColorMain);
    font-weight: 800;
    font-size: 18px;
    margin-top: 0px;
    display: none;
    @media (min-width: 950px) {
        display: block;
    }
`;
