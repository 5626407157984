import PropTypes from "prop-types";
import React, { useState } from "react";

import {
    Modal as BaseModal,
    Typography,
    Text,
    Button,
} from "@prima-afp/prima-ui-elements/dist/core";

import { Icon } from "./styles";

import alertIcon from "../../assets/images/alert.svg";
import happyIcon from "../../assets/images/happy.svg";
import approvedIcon from "../../assets/images/aprobed.svg";

export function ErrorModal(props) {

    const { type, isActive, title, content, textButton } = props.settings;
    const [frameIcon, setFrameIcon] = useState(<></>);

    React.useEffect(() => {
        switch (type) {
            case "alert":
                setFrameIcon(<div className={"tw-mb-1.5"}>
                    <Icon src={alertIcon} alt="modal-alert" />
                </div>);
                break;

            case "happy":
                setFrameIcon(<div className={"tw-mb-1.5"}>
                    <Icon src={happyIcon} alt="modal-alert" />
                </div>);
                break;

            case "approved":
                setFrameIcon(<div className={"tw-mb-1.5"}>
                    <Icon src={approvedIcon} alt="modal-approved" />
                </div>);
                break;

            default: setFrameIcon(<></>);
        }
    }, [type]);

    return (
        <BaseModal show={isActive}>
            <div className={"tw-text-center"}>
                {frameIcon}
                <div className={"tw-mb-1"}>
                    <Typography variant="h3">{title}</Typography>
                </div>
                <div className={"tw-mb-2"}>
                    <Text>{content}</Text>
                </div>

                {content === "Has superado el maximo de intentos para la etapa de reconocimeinto Facial. Por favor vuelve a intentar nuevamente." ? (
                        <Button size={"medium"}>
                            {textButton || 'Cerrar'}
                        </Button>
                    ) : (
                        <Button onClick={props.closeModal} size={"medium"}>
                            {textButton || 'Cerrar'}
                        </Button> 
                    )}

            </div>
        </BaseModal>
    );
}

ErrorModal.propTypes = {
    closeModal: PropTypes.any,
    settings: PropTypes.shape({
        content: PropTypes.any,
        isActive: PropTypes.any,
        title: PropTypes.any,
        type: PropTypes.string,
        textButton: PropTypes.string,
    })
};
