import styled from "styled-components";
import { Text } from "../Text";

export const NavStyled = styled.nav`
    height: 64px;
    margin-bottom: 2rem;
    margin-left:  -1rem;
    margin-right:  -1rem;
    padding-left:  1rem;
    padding-right:  1rem;
    @media (min-width: 950px) {
        box-shadow: none;
        margin-left:  inherit;
        margin-right:  inherit;
        padding-left:  0;
        padding-right:  0;
    }
`;

export const TextStyled = styled(Text)`
    color: var(--orangeColorMain);
    font-weight: 800;
    font-size: 18px;
    margin-top: 0px;
    display: none;
    @media (min-width: 950px) {
        display: block;
    }
`;

export const NavButtonBack = styled.span`
    cursor: pointer;
    color: var(--orangeColorMain);
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    img {
        display: inline-block;
        margin-right: 10px;
    }
`;