import ServiceFetcher from "../../shared/libs/ServiceFetcher";

export const getHeaderQAuth = async (keys, headers) => {
  const response = await fetch(`${process.env.REACT_APP_URL_BASE_OAUTH}`, {
    method: "POST",
    body: keys,
    headers: headers,
  });

  if (!response.ok) {
    throw new Error("Failed to fetch Ocp-Apim-SubKey");
  }

  const data = await response.json();
  return data;
};

export const getDocuments = (headers) =>
  ServiceFetcher(
    `${process.env.REACT_APP_API_URL_DATA_UPDATE_WEB}/update-data-affiliated/`,
    {
      headers: headers,
      method: "GET",
    }
  );

export const validateDocument = (validateDocumentBody, headers) =>
  ServiceFetcher(
    `${process.env.REACT_APP_API_URL_DATA_UPDATE_WEB}/update-data-affiliated/validate-document`,
    {
      headers: headers,
      method: "POST",
      body: validateDocumentBody,
    }
  );

export const saveDataForeign = (saveDataBody, headers) =>
  ServiceFetcher(
    `${process.env.REACT_APP_API_URL_DATA_UPDATE_WEB}/update-data-affiliated/foreign`,
    {
      headers: headers,
      method: "POST",
      body: saveDataBody,
    }
  );
