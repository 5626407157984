import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const initialState = {
  currentStep: 1,
  showLoading: false,
  modalOptions: {
    isActive: false,
    title: '',
    content: '',
    type: '',
    textButton: "",
  },
  userWeb: {},

  imageBiometric: null,
  biometricVerified: false,

  fileDocumentFront: null,
  fileDocumentBack: null,

  isForeing: false,
  identifyTypeDocument: '',
  identifyDocument: {
    typeDocument: '',
    numberDocument: ''
  }
};

const __reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL_STATE':
      return { ...initialState };
    case 'FIELD':
      return { ...state, [action.payload.field]: action.payload.value };
    case 'FIELD_SECOND':
      return {
        ...state,
        [action.payload.field1]: {
          ...state[action.payload.field1],
          [action.payload.field2]: action.payload.value,
        },
      };
    case 'VALID_DOCUMENT':
      return {
        ...state,
        // showPersonalData: true,
        // showContactData: false,
        userWeb: { ...action.payload },
      };
    case 'IDENTIFY_DOCUMENT':
      return {
        ...state,
        identifyDocument: {
          typeDocument: action.payload.typeDocument,
          numberDocument: action.payload.numberDocument
        }
      };
    case 'VALIDATION_BIOMETRIC':
      return { ...state, biometricVerified: true };
    case 'NOT_VALIDATION_BIOMETRIC':
      return { ...state, biometricVerified: false };
    case 'SET_CONFIG_MODAL':
      return {
        ...state,
        modalOptions: {
          isActive: true,
          title: action.payload.title || 'Aviso',
          content: action.payload.content || '',
          type: action.payload.type || 'alert',
          textButton: action.payload.textButton || "Cerrar",
        },
        showLoading:
          action.payload.changeLoading !== undefined
            ? action.payload.changeLoading
            : state.showLoading,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        modalOptions: {
          isActive: false,
        },
      };
    default:
      return state;
  }
};

const StateContext = createContext();
const DispatchContext = createContext();

const BiometricsDataUpdateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(__reducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useStateBiometricsDataUpdateContext = () =>
  useContext(StateContext);
export const useDispatchBiometricsDataUpdateContext = () =>
  useContext(DispatchContext);

export default BiometricsDataUpdateProvider;

BiometricsDataUpdateProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
