const sendToDataLayerATM = (eventName, eventParams) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "atm.event",
      event_name: eventName,
      ...eventParams,
    });
  }
};

export const GTMEvents = {
  BtnAcceptErrorModalStepCero: () => {
    sendToDataLayerATM("button_click", {
      id: "MOD-UPDATE-DATA-BTN-ACCEPT-ERROR-MODAL-STEP-CERO",
      section: "actualización datos",
      sub_section:"modal error - renderización inicial",
      option: "entendido",
      procedure_step: "paso 0",
      location: "/actualizacion-datos-biometria",
    });
  },

  ErrorModalRenderUserNotFoundStepCero: () => {
    sendToDataLayerATM("type_error", {
      id: "MOD-UPDATE-DATA-ERROR-MODAL-RENDER-USER-NOT-FOUND-STEP-CERO",
      section: "actualización datos",
      option: "error en la aplicación - usuario no encontrado",
      procedure_step: "paso 0",
      location: "/actualizacion-datos-biometria",
    });
  },

  BtnAcceptErrorUserNotFoundModalStepCero: () => {
    sendToDataLayerATM("button_click", {
      id: "MOD-UPDATE-DATA-BTN-ACCEPT-ERROR-MODAL-USER-NOT-FOUND-STEP-CERO",
      section: "actualización datos",
      sub_section:"modal error - usuario no encontrado",
      option: "entendido",
      procedure_step: "paso 0",
      location: "/actualizacion-datos-biometria",
    });
  },

  BtnValidateDocument: () => {
    sendToDataLayerATM("button_click", {
      id: "MOD-UPDATE-DATA-BTN-VALIDATE-DOCUMENT",
      section: "actualización datos",
      option: "validar",
      procedure_step: "paso 0",
      location: "/actualizacion-datos-biometria",
    });
  },

  BtnNavbarBackOptionStepOne: () => {
    sendToDataLayerATM("button_click", {
      id: "MOD-UPDATE-DATA-BTN-NAVBAR-BACK-OPTION-STEP-ONE",
      section: "actualización datos",
      sub_section: "deseas actualizar los datos de contacto",
      option: "atrás",
      procedure_step: "paso 1",
      location: "/actualizacion-datos-biometria/form",
    });
  },

  BtnOptionsStepOne: (option) => {
    sendToDataLayerATM("button_click", {
      id: "MOD-UPDATE-DATA-BTN-OPTIONS-STEP-ONE",
      section: "actualización datos",
      sub_section: "deseas actualizar los datos de contacto",
      option: option,
      procedure_step: "paso 1",
      location: "/actualizacion-datos-biometria/form",
    });
  },

  ErrorFormInputDataUserToChange: (option) => {
    sendToDataLayerATM("type_error", {
      id: "MOD-UPDATE-DATA-ERROR-FORM-INPUT-DATA-USER-TO-CHANGE",
      section: "actualización datos",
      option: option,
      procedure_step: "paso 1",
      location: "/actualizacion-datos-biometria/form",
    });
  },

  RenderFinalStep: () => {
    sendToDataLayerATM("step", {
      id: "MOD-UPDATE-DATA-RENDER-FINAL-STEP",
      section: "actualización datos",
      sub_section: "modal solicitud finalizada - paso final",
      procedure_step: "final step",
      location: "/actualizacion-datos-biometria/finished",
    });
  },
};
