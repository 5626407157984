import React from 'react';
import { Router } from './router';

import { GlobalStyle } from '@prima-afp/prima-ui-elements/dist/layouts';
import '@prima-afp/prima-tailwind-utils/dist/tailwind-customized.css';

const App = () => {
  return (
    <GlobalStyle>
      <Router />
    </GlobalStyle>
  );
};
export default App;
