import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { NavStyled, NavButtonBack, TextStyled } from "./styles";
import Logo from "../../../shared/assets/images/prima-afp-logo-orange.svg";
import IcoBotonBack from "../../../shared/assets/images/ico-back.svg";
import {
  useDispatchBiometricsDataUpdateContext,
  useStateBiometricsDataUpdateContext,
} from "../../context/BiometricsDataUpdateContext";
import { GTMEvents } from "../../../shared/utils/GTMevents";

export const Navbar = ({ back }) => {
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    setCurrentPath(history.location.pathname);
  }, []);

  const { currentStep } = useStateBiometricsDataUpdateContext();

  const dispatch = useDispatchBiometricsDataUpdateContext();

  const handleStepsBack = () => {
    dispatch({
      type: "FIELD",
      payload: { field: "currentStep", value: currentStep - 1 },
    });
  };

  const handleBack = () =>
    currentStep > 1
      ? handleStepsBack()
      : history.push("/actualizacion-datos-biometria");

  const GTMeventsFuntion = () => {
    if (currentPath === "/actualizacion-datos-biometria/form") {
      GTMEvents.BtnNavbarBackOptionStepOne();
    }
  };

  return (
    <NavStyled>
      <div className="tw-flex tw-flex-wrap tw-h-full tw-justify-between tw-content-center">
        {back ? (
          <NavButtonBack
            role="button"
            onClick={() => {
              handleBack();
              GTMeventsFuntion();
            }}
          >
            <img src={IcoBotonBack} alt="Regresar" /> Atrás
          </NavButtonBack>
        ) : (
          <img src={Logo} alt="logo PRIMA" />
        )}
        <TextStyled>Ayer. Hoy. Siempre</TextStyled>
      </div>
    </NavStyled>
  );
};

Navbar.propTypes = {
  back: PropTypes.any,
};
