import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../../shared/assets/images/prima-afp-logo.svg';
import LogoOrange from '../../../shared/assets/images/prima-afp-logo-orange.svg';
import { SidebarWrap, SidebarContent, TextStyled } from "./styles";
import icoBannerLateral from '../../../shared/assets/images/ico-bannerLateral.svg';
import { useWindowSize } from 'modules/shared/libs/helpers';

export const Sidebar = ({currentStep}) => {

  const isDesktop = useWindowSize(); // Si es TRUE es DESKTOP

    return (
        <SidebarWrap className={(!currentStep && !isDesktop) ? 'sidebarHeight': 'sidebarHeightMobileStep'}>
           
          {currentStep && (
            <div className='sidebarTop'>
              <img src={Logo} alt='' className='logo' />
              <TextStyled>Ayer. Hoy. Siempre</TextStyled>
            </div>
          )}

          {(!isDesktop && !currentStep) && (
            <div className='sidebarTop'>
              <img src={Logo} alt='' className='logo' />
              <TextStyled>Ayer. Hoy. Siempre</TextStyled>
            </div>
          )}

          {(!isDesktop && currentStep) && (
            <div className='sidebarTop'>
              <img src={LogoOrange} alt='' className='logo' />
              <TextStyled className='textOrange'>Ayer. Hoy. Siempre</TextStyled>
            </div>
          )}

          {((!isDesktop && !currentStep) || (isDesktop && !currentStep) || (isDesktop && currentStep)) && (
          <SidebarContent>           
            <img src={icoBannerLateral} className='ico' alt='' />
            <span>El sólido respaldo de Credicorp, líder en el país y con más de 130 años de experiencia.</span>
          </SidebarContent>
          )}
          
        </SidebarWrap>
    );
};

Sidebar.propTypes = {
  currentStep: PropTypes.any
};