/* eslint-disable react/display-name */
import React from 'react';
import DataUpdateWebServiceFetcher from "modules/shared/libs/ServiceFetcher";
import FailedValidationIcon from 'modules/shared/assets/images/face-failed-rejected.svg';

const buttonFailedContent = {
    title: 'Ir al inicio',
    url: '/actualizacion-datos-biometria/',
};

const biometricValidationModuleConfig = {
    ServiceFetcher: DataUpdateWebServiceFetcher,
    pathBase: '/validacion-biometrica',
    urlSuccessValidation: '/actualizacion-datos-biometria/otp',
    initialRedirectValidation: true,
    buttonAdviceInProcess: {
        title: 'Entendido',
        url: '/actualizacion-datos-biometria',
    },
    buttonFailedValidation: { ...buttonFailedContent },
    buttonValidationRejected: { ...buttonFailedContent },
    buttonFailedDocumentExpired: { ...buttonFailedContent },
    contentAdviceInProcess: {
        title: 'No pudimos validar tu identidad',
        attentionTimeRequestId: 62,
        content: (attention) => (
            <>
                No te preocupes, por tu seguridad y la de tu fondo,
                la actualización de datos <b> estará disponible nuevamente el día de mañana.</b>
            </>
        ),
        icon: FailedValidationIcon,
    },
    contentValidationRejected: {
        title: 'No hemos podido validar tu identidad',
        content: (attention) => (
            <>
                No te preocupes, por tu seguridad y la de tu fondo,
                la actualización de datos <b> estará disponible nuevamente el día de mañana.</b>
            </>
        ),
        icon: FailedValidationIcon,
    },
    contentFailed: {
        title: 'No hemos podido validar tu identidad',
        colorTitle: '#FF4F00',
        content: (attention) => (
            <>
                No te preocupes, por tu seguridad y la de tu fondo,
                la actualización de datos <b> estará disponible nuevamente el día de mañana.</b>
            </>
        ),
        icon: FailedValidationIcon,
    },
    contentFailedDocumentExpired: {
        // title: 'No hemos podido validar tu identidad - not shown: showCustomTitle = false',
        showCustomTitle: false,
        colorTitle: '#FF4F00',
        content: (attention) => (
            <>
                No te preocupes, por tu seguridad y la de tu fondo,
                la actualización de datos <b> estará disponible nuevamente el día de mañana.</b>
            </>
        ),
        icon: FailedValidationIcon,
    },
};

const custom = {
    'identityValidationPage': {
        components: {
            'IdentityValidationHeader': true,
            'IdentityValidationContainerContent': true,
            'initialBiometricValidationHOC': true,
        },
    },
    'captureSelfiePage': {
        components: {
            'IdentityValidationHeader': true,
            'ContainerCaptureSelfie': true
        },
    },
    'IdentityValidationFailedPage': {
        components: {
            'IdentityValidationHeader': true,
        },
    },
    'IdentityValidationFailedButton': {
        bypassForeignValidation: true,
        bypassForeignValidationUrl: '/actualizacion-datos-biometria/validacion-adicional-extranjeros',
    },
    'UploadDocumentsPage': {
        components: {
            'IdentityValidationHeader': true,
        },
    },
    'ShowSelfieCapturePage': {
        components: {
            'IdentityValidationHeader': true,
        },
    },
    'ValidationForeignUserPage': {
        components: {
            'IdentityValidationHeader': true,
        },
    },
    'UploadDocumentsMobilePage': {
        'redirectToPathBase_onExtractionTimeout': true,
    },
    'SelectCountryPage': {
        components: {
            'IdentityValidationHeader': true,
        },
    },
    'ExpiredDocumentPage': {
        components: {
            'IdentityValidationHeader': true,
        },
    },
    shared: {
        apis: {
            'useCreateRequest': {
                baseUrl: process.env.REACT_APP_API_URL_DATA_UPDATE_WEB,
                endpoint: '/affiliate/biometric/v1/create-validation-request',
            },
            'useBiometricDetail': {
                baseUrl: process.env.REACT_APP_API_URL_DATA_UPDATE_WEB,
                endpoint: '/affiliate/biometric/v1/detail',
            },
            'useChangeStatusIdentityValidation': {
                baseUrl: process.env.REACT_APP_API_URL_DATA_UPDATE_WEB,
                endpoint: '/affiliate/biometric/v1/${biometricValidationRequestId}/${status}',
            },
            'useGetTried': {
                baseUrl: process.env.REACT_APP_API_URL_DATA_UPDATE_WEB,
                endpoint: '/affiliate/biometric/v1',
                customOptions: true,
            },
            'useValidationPassiveMode': {
                baseUrl: process.env.REACT_APP_API_URL_DATA_UPDATE_WEB,
                endpoint: (isForeign) => { return isForeign ? '/affiliate/biometric/v1/facephi/passive-liveness/token/${biometricValidationRequestId}': '/affiliate/biometric/v2/facephi/passive-liveness/token/${biometricValidationRequestId}'},
            },
            'useMorphologicalValidation': {
                baseUrl: process.env.REACT_APP_API_URL_DATA_UPDATE_WEB,
                endpoints: {
                    'start': '/affiliate/biometric/v2/facephi/document-validation/start',
                    'status': '/affiliate/biometric/v1/facephi/document-validation/status',
                    'data': '/affiliate/biometric/v1/facephi/document-validation/data',
                },
                bypassForeignValidation: true,
            },
            'useSendValidationPhoto': {
                baseUrl: process.env.REACT_APP_API_URL_DATA_UPDATE_WEB,
                endpoint: '/affiliate/biometric/v2/validate/${requestId}',
                'selfieImageTokenized': true
            },
        },
        components: {
            'ModalWarningCamera': {
                hideRetries: true,
            },
            'MorphologicalValidationData': {
                customBody: true,
            }
        },
    },
}

export default {
    ...biometricValidationModuleConfig,
    privateMepaWeb: false,
    custom,
}