import { useMutation, useQueryClient } from 'react-query';
import { getHeaderQAuth } from '../services';

export const useGetTokenQauth = (keys, headers) => {
  const queryClient = useQueryClient();
  
  return useMutation(
    () => getHeaderQAuth(keys, headers),
    {
      onSuccess: (data) => {
        // Asumiendo que la respuesta `data` contiene tu token en data.token.
        const token = data.access_token;
        
        // Almacena el token en la caché
        queryClient.setQueryData('authToken', { Authorization: `Bearer ${token}` });
        localStorage.setItem('authToken', JSON.stringify({ Authorization: `Bearer ${token}` }));
      },
    }
  );
};