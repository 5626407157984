import styled from "styled-components";
import BackgroundSidebar from '../../../shared/assets/images/bannerLateral.jpg';
import BackgroundSidebarMobile from '../../../shared/assets/images/bannerLateralMobile.jpg';
import { Text } from "../Text";

export const SidebarWrap = styled.div`
  position: relative;
  order: 1;
  box-shadow: 2px 2px 10px 2px #EBEBEB;
  height: 64px;
  &.sidebarHeight {
    background-position: bottom left;
    background-repeat: no-repeat;
    background-image: url(${BackgroundSidebarMobile});
    background-size: auto 100%;
    height: 284px;
    @media (min-width: 640px) {
      background-size: 100% auto;
    }
    @media (min-width: 950px) {
      height: 100vh;
    }
  }  
  .logo { 
    @media (min-width: 950px) {
      display: block;
      width: 120px;
    }
  }
  .sidebarTop {
    width: 100%;
    position: absolute;
    top: 34px;
    left: 0;
    padding: 0 34px;
    display: flex;
    justify-content: space-between;
    color: white;
  }
  &.sidebarHeightMobileStep {
    .sidebarTop {
      position: absolute;
      top: 24px;
    }
  }
  @media (min-width: 950px) {
    box-shadow: none;
    background-image: url(${BackgroundSidebar});
    background-position: top left;
    background-size: cover;
    height: 100vh;
    display: grid;
    gap: 0px;
    grid-template-columns: 445px 1fr;
  }
`;

export const SidebarContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 34px;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  > .ico {
    width: 39px;
    height: 49px;
  }
  > span {
    display: block;
    padding: 1px 0 0 0;
  }
  @media (min-width: 950px) {
    padding: 64px;
    font-size: 20px;
    line-height: 24px;
  }
`;

export const SidebarContentIco = styled.div`
  width: 100%;
`;

export const TextStyled = styled(Text)`
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: white;
  display: block;
  @media (min-width: 950px) {
      display: none;
  }
  &.textOrange {
    color: var(--orangeColorMain);
  }
`;
