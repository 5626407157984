import { useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { saveDataForeign } from '../services';
import { useHistory } from 'react-router-dom';
const postValidateForeign = async ({body, token}) => {
  if(!token){
    return;
  }
  const res = await saveDataForeign(body, token);
  const data = await res;
  return data;
};

export const usePostChangeForeign = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isErrorModalActive, setIsErrorModalActive] = useState(false);

  const { mutate, isLoading, error, data, isSuccess } = useMutation(
    postValidateForeign,
    {
      onError: (error) => {
        setIsErrorModalActive(true);
      },
      onSuccess: (data) => {
        setErrorMessage(null);
          history.push('/actualizacion-datos-biometria/finished')
      },
    }
  );

  const handleCloseModal = () => {
    setIsErrorModalActive(false);
  };

  return {
    mutate,
    isLoading,
    error,
    errorMessage,
    data,
    isSuccess,
    handleCloseModal,
    modalSettings: {
      type: 'alert',
      isActive: isErrorModalActive,
      title: 'Error',
      content:
        'Lo sentimos, estamos trabajando para solucionar el inconveniente.',
      closeModal: handleCloseModal,
    },
  };
};
