import React from 'react';

const AppContext = React.createContext();

export function AppProvider({ children, biometric }) {
  return (
    <AppContext.Provider value={{ biometric }}>
        { children }
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error('AppContext provider is required');
  }
  return context;
}
