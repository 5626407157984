import React, {useEffect} from 'react';
import { FPhi as FPhiSelphID } from '@prima-afp/selphid-widget-component';
import { FPhi as FPhiSelphi } from '@prima-afp/selphi-widget-component';

// modules
import { BiometricValidationCommon as BiometricDataUpdateWeb } from '@prima-afp/prima-security/module';
import { BiometricsDataUpdateModule } from '../modules';
import * as MODULES_CONFIG from './config';
import { ApplicationRouter } from '../components';
import { AppProvider, useAppContext } from '../context';
import { Content, Wrapper } from 'modules/biometrics-data-update-new/pages/styles';
import { Sidebar } from 'modules/biometrics-data-update-new/components/Sidebar';
import { useGetTokenQauth } from 'modules/biometrics-data-update-new/hooks/useGetTokenQauth';
import { generateModalError } from 'modules/shared/libs/ErrorHandler';
import { BODY_REQUEST_GENERATION, HEADER_REQUEST_GENERATION } from 'modules/shared/constants';

const Router = () => {

  const { mutate: fetchToken, isSuccess} = useGetTokenQauth(BODY_REQUEST_GENERATION, HEADER_REQUEST_GENERATION,);
  
  useEffect(() => {
    fetchToken();
  }, []);

  const addGlobalConfig = (config = {}) => ({
    ...config,
    app: {
      useGlobalAppContext: useAppContext
    }
  });
  if(!isSuccess){
        generateModalError({ 
            errorCode: 401,
            descriptionErrorCode: 'Nuestro sistema tuvo un problema',
            customMessage:'Lo sentimos, intenta ingresar nuevamente en unos minutos.'
        }); 
  }
  return (

    <AppProvider biometric={{ FPhiSelphID, FPhiSelphi }}>
      <Wrapper>
        <Sidebar />
        <Content>
          {
            isSuccess && (
            <ApplicationRouter
            publicModules={[
              BiometricDataUpdateWeb(addGlobalConfig(MODULES_CONFIG.biometricDataUpdateWebModuleConfig)),
              BiometricsDataUpdateModule(),
            ]}
            />
            )
          }

        </Content>
      </Wrapper>
    </AppProvider>
  );
};

export default Router;
