import React, { useEffect, useState } from 'react';
import StepOne from '../StepContactPersonal';
import { MainContainer } from '../../components/MainContainer';
import { Navbar } from '../../components/Navbar';

export default function FormPage() {
  const [currentStep, setCurrentStep ] = useState(1);
  const [frame, setFrame] = useState(<></>);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setFrame(<StepOne setCurrentStep={setCurrentStep} />);
        break;
      default:
        setFrame(<></>);
    }
  }, [currentStep]);

  return (
    <>
        <>        
          <MainContainer>
            <Navbar back={true} />
            {frame}
          </MainContainer>
      </>
    </>
  );
}
