import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import {
  Modal as BaseModal,
  Typography,
  Text,
  Button,
} from '@prima-afp/prima-ui-elements/dist/core';
import { Link } from 'react-router-dom';

import { Icon } from './styles';

import alertIcon from '../../assets/images/alert.svg';
import { GTMEvents } from '../../utils/GTMevents';

export function ErrorModal(props) {
  const type = props.settings.type;
  const isActive = props.settings.isActive;
  const content = props.settings.content;
  const closeModal = props.closeModal;

  useEffect(() => {
    if (
      content ===
      'No hemos podido identificarte como nuestro afiliado. Inténtalo nuevamente.'
    ) {
      GTMEvents.ErrorModalRenderUserNotFoundStepCero();
    }
  }, [content]);

  return (
    <BaseModal show={isActive} centered={true}>
      <div className={'tw-text-center'}>
        {type === 'alert' ? (
          <div className={'tw-mb-1.5'}>
            <Icon src={alertIcon} alt="modal-alert" />
          </div>
        ) : (
          <></>
        )}
        <div className={'tw-mb-1'}>
          <Typography variant="h3">Error en la aplicación</Typography>
        </div>
        <div className={"tw-mb-2"}>
          <Text>{content}</Text>
        </div>
        <Link to="/mis-tramites">
          <Button
            onClick={() => {
              closeModal();
              content ===
              'No hemos podido identificarte como nuestro afiliado. Inténtalo nuevamente.'
                ? GTMEvents.BtnAcceptErrorUserNotFoundModalStepCero()
                : GTMEvents.BtnAcceptErrorModalStepCero();
            }}
            size={'medium'}
          >
            Entendido
          </Button>
        </Link>
      </div>
    </BaseModal>
  );
}

ErrorModal.propTypes = {
  closeModal: PropTypes.any,
  settings: PropTypes.shape({
    content: PropTypes.any,
    isActive: PropTypes.any,
    title: PropTypes.any,
    type: PropTypes.string,
  }),
};
