import styled from "styled-components";
import { Modal } from "@prima-afp/prima-ui-elements/dist/core";

export const StyledModal = styled(Modal)`
  div {
    margin-top: 60px;
    background-color: initial;
    box-shadow: none;
  }
`;
