import styled from 'styled-components';

import { Button } from '@prima-afp/prima-ui-elements/dist/core';

export const StyledButton = styled(Button)`
  width: 100%;
  /* height: 48px; */
  @media (min-width: 768px) {
    width: auto;
  }
`;
