import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as PAGES from '../pages'
import BiometricsDataUpdateProvider from '../context/BiometricsDataUpdateContext';
import { PATH_BASES } from '../../shared/constants';

const BiometricsDataUpdateModuleRouter = () => {
  return (
    <BiometricsDataUpdateProvider>
      <Switch>
        <Route exact path={`${PATH_BASES.DATA_UPDATE}`} component={PAGES.HomePage} />
        <Route exact path={`${PATH_BASES.DATA_UPDATE}/form`} component={PAGES.FormPage} />
        <Route exact path={`${PATH_BASES.DATA_UPDATE}/otp`} component={PAGES.StepOtpVerification} />
        <Route exact path={`${PATH_BASES.DATA_UPDATE}/finished`} component={PAGES.Finished} />
        <Route exact path={`${PATH_BASES.DATA_UPDATE}/validacion-adicional-extranjeros`} component={PAGES.AdditionalValidationForeigners} />
        <Route exact path={`${PATH_BASES.DATA_UPDATE}/maximos-intentos`} component={PAGES.MaxAttempts} />
        <Redirect to={`${PATH_BASES.DATA_UPDATE}`} />
      </Switch>
    </BiometricsDataUpdateProvider>
  );
};

export default BiometricsDataUpdateModuleRouter;
