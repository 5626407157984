import { ServiceFetcherCreate } from "@prima-afp/common-libs/dist/libs/http";
import AppSession from "./AppSession";

const getTokenAuth = JSON.parse(localStorage.getItem("authToken"));
const tokenAuth = getTokenAuth?.Authorization?.split(" ")[1];
const DataUpdateWebServiceFetcher = ServiceFetcherCreate({
  tokenPathUrl: `${process.env.REACT_APP_URL_BASE_OAUTH}`,
  baseUrl: process.env.REACT_APP_API_URL_DATA_UPDATE_WEB,
  accessTokenGetter: () => `${tokenAuth}`,
  onGetToken: (response) =>
    localStorage.setItem("authToken", prop("authToken", response)),
  onError: (error) => {
    console.error("[DataUpdateWeb][ServiceFetcher]", error);
  },
});

export default DataUpdateWebServiceFetcher;
