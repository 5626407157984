import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { prop } from 'ramda';

const PublicRouter = ({ publicModules = [] }) => {

  return (
    <Switch>
      {
        publicModules.map((module) => (
          <Route
            path={prop('pathBase', module)}
            key={prop('pathBase', module)}
            component={() => <module.componentRouter />}
          />
        ))
      }
      <Redirect from={'*'} to={'/'} />
    </Switch>
  );
};

export default PublicRouter;