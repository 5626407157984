import { EventBus } from "@prima-afp/common-libs/dist/libs/utils";

export const generateModalError = ({
  errorCode,
  descriptionErrorCode,
  customMessage,
  endpoint = "",
  requestOptions = {},
  excludedEndPoints = [],
}) => {
  const endpointIsExcluded = excludedEndPoints.filter(
    (request) =>
      request.endpoint === endpoint && request.method === requestOptions.method
  );
  if (endpointIsExcluded.length > 0) {
    return;
  }

  const isError = lookForMessageError(errorCode).length > 0;
  if (!isError) return;

  const { title, message } = getErrorMessage({
    errorCode,
    descriptionErrorCode,
    customMessage,
  });
  EventBus.emitEvent("globalErrorModal", {
    show: true,
    title,
    message,
  });
};

const getErrorMessage = ({
  errorCode,
  descriptionErrorCode,
  customMessage,
}) => {
  if (customMessage) {
    const { title, message } = customMessage;
    return {
      title,
      message,
    };
  } else {
    const _errorCode = errorCode ? errorCode : 500;
    const _descriptionErrorCode = descriptionErrorCode
      ? descriptionErrorCode
      : "";
    const errorFound = lookForMessageError(
      _errorCode,
      _descriptionErrorCode
    )[0];
    return errorFound;
  }
};

const lookForMessageError = (errorCode, descriptionErrorCode = "") => {
  return HTTP_ERROR_LIST.filter(
    (error) =>
      error.code === errorCode ||
      error.descriptionErrorCode === descriptionErrorCode
  );
};

const HTTP_ERROR_LIST = [
  {
    code: 500,
    descriptionErrorCode: "INTERNAL_SERVER_ERROR",
    title: "Nuestro sistema tuvo un problema",
    message: "Lo sentimos, intenta ingresar nuevamente en unos minutos.",
  },
  {
    code: 404,
    descriptionErrorCode: "INTERNAL_SERVER_ERROR",
    title: "Nuestro sistema tuvo un problema",
    message: "Lo sentimos, intenta ingresar nuevamente en unos minutos.",
  },
];
