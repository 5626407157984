import PropTypes from 'prop-types';
import React from 'react';
import { Container } from '@prima-afp/prima-ui-elements/dist/layouts';
import Loading from '../../../shared/components/Loading';
import {
  useDispatchBiometricsDataUpdateContext,
  useStateBiometricsDataUpdateContext,
} from '../../context/BiometricsDataUpdateContext';
import { ErrorModal } from '../../../shared/components/ErrorModal';
// import { ErrorModal } from '../ErrorModal';
// import { useDispatchDigitalTransfersContext, useStateDigitalTransfersContext } from "../../context/DigitalTransfersContext";
// import Loading from '../Loading';

export const MainContainer = ({ children }) => {
  const { modalOptions, showLoading } = useStateBiometricsDataUpdateContext();
  const dispatch = useDispatchBiometricsDataUpdateContext();

  return (
    <>
      <Container>
        {children}
      </Container>

      <ErrorModal
        settings={modalOptions}
        closeModal={() =>
          dispatch({
            type: 'CLOSE_MODAL',
          })
        }
      />

      <Loading show={showLoading} />
    </>
  );
};

MainContainer.propTypes = {
  children: PropTypes.any,
};
