import React, { useState } from 'react';
import {
  Input,
  Option,
  RadioButton,
  Select,
  StepsCircle,
  Typography,
} from '@prima-afp/prima-ui-elements/dist/core';
import { StepTitle } from '../../../shared/components/StepTitle';
import { FormContainer } from '../styles';
import { Steps } from '../styles';
import { MainContainer } from '../../components/MainContainer';
import { StyledButton } from '../../components/Button/styles';
import { Container } from '@prima-afp/prima-ui-elements/dist/layouts';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { GTMEvents } from '../../../shared/utils/GTMevents';

const initialState = [
  {
    id: 1,
    title: 'Datos de contacto',
    current: true,
    active: false,
  },
  {
    id: 2,
    title: 'Validación de identidad',
    current: false,
    active: false,
  },
];

export default function StepOne({ setCurrentStep }) {
  const history = useHistory();
  const queryClient = useQueryClient();
  const data = queryClient.setQueryData(
    'validateDocument',
    JSON.parse(localStorage.getItem('validateDocument'))
  );
  const typeDoc = JSON.parse(localStorage.getItem('typeDocument'));
  const { documentType, documentNumber, name, lastName, email, phoneNumber } =
    !!data?.userWebDataDto && data?.userWebDataDto;
  const [identificationDocumentType, setIdentificationDocumentType] =
    useState(documentType);
  const [identificationDocumentNumber, setIdentificationDocumentNumber] =
    useState(documentNumber);
  const [identificationPhoneNumber, setIdentificationPhoneNumber] =
    useState('');
  const [patchEmail, setPatchEmail] = useState('');//email as change
  const [patchEmailConfirm, setPatchEmailConfrim] = useState('');//email confirm
  const [steps, setSteps] = useState(initialState);
  const [maxLength, setMaxLength] = useState(8);
  const [focusEmail, setFocusEmail] = useState(true);
  const [focusEmailConfirm, setFocusEmailConfirm] = useState(true);
  const [focusPhoneConfirm, setFocusPhoneConfirm] = useState(true);
  const [isUpdateDataContact, setIsUpdateDataContact] = useState({
    firstCheck: false,
    secondCheck: false,
  });
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailConfirmError, setEmailConfirmError] = useState('');
  const [disabledButton, setDisabledButton] = useState(true);
  const [inputValue, setInputValue] = useState('');//phoneNumber
  const [isFormFilled, setIsFormFilled] = useState(false);

  const handleNextStep = () => {
    localStorage.setItem(
      'otpVerifycate',
      JSON.stringify({
        "phoneNumber": inputValue,
        "email":patchEmail,
        "emailConfirm":patchEmailConfirm,
        "name": name
      })
    );
    localStorage.setItem(
      'userData',
      JSON.stringify({
        "patchEmail": patchEmail,
      })
    );
    history.push({
      pathname: '/validacion-biometrica/validacion-inicial/62',
      state: {
        initialRedirect: true,
        document_type: documentType,
        document_number: identificationDocumentNumber,
      },
    });
  };

  const handleFinish = () => {
    history.push({
      pathname: '/actualizacion-datos-biometria',
      state: {
        initialRedirect: true,
        document_type: documentType,
        document_number: identificationDocumentNumber,
      },
    });
  };
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  useEffect(() => {
    const isEmailValid =
      isValidEmail(patchEmail) && isValidEmail(patchEmailConfirm);
    const isPhoneValid =
      documentType === "00" ? isValidPhone(inputValue) : true;
    const isRequiredFieldsFilled =
      patchEmail && patchEmailConfirm && (inputValue || documentType !== "00");
    const isDisabled =
      !isEmailValid || !isPhoneValid || !isRequiredFieldsFilled;
    setDisabledButton(isDisabled);
    setIsFormFilled(isEmailValid && isPhoneValid && isRequiredFieldsFilled);
  }, [patchEmail, patchEmailConfirm, inputValue, documentType]);

  const handleBlur = () => {
    if (!patchEmail.trim()) {
      setEmailError('Dato Obligatorio');
      GTMEvents.ErrorFormInputDataUserToChange('Dato Obligatorio  - Correo Electrónico')
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(patchEmail)) {
      setEmailError('Formato de correo electrónico inválido');
      GTMEvents.ErrorFormInputDataUserToChange('Formato de correo electrónico inválido')
    } else {
      setEmailError('');
    }
  };

  const handleBlurConfirm = () => {
    if (!patchEmailConfirm.trim()) {
      setEmailConfirmError('Dato Obligatorio');
      GTMEvents.ErrorFormInputDataUserToChange('Dato Obligatorio - Correo Electrónico a Confirmar')
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(patchEmail)) {
      setEmailConfirmError('Formato de correo electrónico inválido');
      GTMEvents.ErrorFormInputDataUserToChange('Formato de correo electrónico a confirmar inválido')
    } else if (patchEmail && patchEmailConfirm !== patchEmail) {
      setEmailConfirmError('El correo electrónico no coincide');
      GTMEvents.ErrorFormInputDataUserToChange('El correo electrónico no coincide')
    } else {
      setEmailConfirmError('');
    }
  };

  const handleBlurPhone = () => {
    if (!inputValue.trim()) {
      setPhoneError('Dato Obligatorio');
      GTMEvents.ErrorFormInputDataUserToChange('Dato Obligatorio - Celular')
    } else if (inputValue.length !== 9 || !inputValue.startsWith('9')) {
      setPhoneError('Número de celular inválido');
      GTMEvents.ErrorFormInputDataUserToChange('Número de celular inválido')
    } else {
      setPhoneError('');
    }
  };

  const isValidPhone = (phoneNumber) => {
    if (!phoneNumber.trim()) {
      return false;
    }
    if (phoneNumber.length !== 9 || !phoneNumber.startsWith('9')) {
      return false;
    }
    return true;
  };

  return (
    <>
      <MainContainer>
        <Steps>
          <StepsCircle center steps={steps} />
        </Steps>

        <StepTitle className="tw-mb-1.5 tw-mt-2">
          Verifica tus datos personales
        </StepTitle>

        <FormContainer className="tipoDocumento">
          <Select
            className="tipoDocumento-select"
            label={''}
            value={identificationDocumentType}
            disabled
          >
            <Option value={'00'} key={'00'}>
              {'DNI'}
            </Option>
            <Option value={'01'} key={'01'}>
              {'CE'}
            </Option>
            <Option value={'04'} key={'04'}>
              {'PAS'}
            </Option>
            <Option value={'06'} key={'06'}>
              {'PTP'}
            </Option>
          </Select>
          <Input
            className="tipoDocumento-input"
            type="text"
            value={identificationDocumentNumber}
            maxLength={maxLength}
            disabled
          />
        </FormContainer>

        <div className="md:tw-flex tw-justify-between tw-mb-1">
          <div style={{ width: '100%', 'margin-right': '1%' }} className='tw-mb-1 md:tw-mb-0'>
            <Input
              type="text"
              label="Nombres"
              className="md:tw-mr-1"
              value={name}
              disabled
            />
          </div>
          <Input
            type="text"
            label="Apellidos"
            className="md:tw-ml-1"
            value={lastName}
            disabled
          />
        </div>

        <div className="md:tw-flex tw-justify-between tw-mb-3">
          <div
            style={{
              width: documentType !== '00' ? '50%' : '100%',
              marginRight: '1%',
            }}
            className='tw-mb-1 md:tw-mb-0'
          >
            {' '}
            <Input
              type="text"
              label="Correo"
              className="md:tw-mr-1"
              value={!email ? ' ' : email}
              disabled
            />
          </div>
          {documentType === '00' && (
            <Input
              type="text"
              label="Celular"
              className="md:tw-ml-1"
              value={ !phoneNumber ? ' ' :phoneNumber}
              disabled
            />
          )}
        </div>

        {/* ACTUALIZACION DATOS CONTACTO */}

        <div className="tw-justify-between tw-mb-1">
          <Typography className="tw-mb-2">
            ¿Deseas actualizar los datos de contacto que tenemos registrados?
          </Typography>

          <div className="tw-flex tw-justify-between tw-mb-1">
            <div className="tw-flex tw-justify-start tw-w-1/2">
              <RadioButton
                className="tw-mb-1.5"
                label="Si"
                value="y"
                checked={isUpdateDataContact?.firstCheck}
                onClick={() => {
                  GTMEvents.BtnOptionsStepOne("si")
                  setIsUpdateDataContact({
                    firstCheck: true,
                    secondCheck: false,
                  });
                  setDisabledButton(false);
                }}
              ></RadioButton>
            </div>
            <div className="tw-flex tw-justify-start tw-w-1/2">
              <RadioButton
                label="No"
                value="n"
                checked={isUpdateDataContact?.secondCheck}
                onClick={() => {
                  GTMEvents.BtnOptionsStepOne("no")
                  setIsUpdateDataContact({
                    firstCheck: false,
                    secondCheck: true,
                  });
                  setDisabledButton(false);
                }}
              ></RadioButton>
            </div>
          </div>

          {isUpdateDataContact?.firstCheck && (
            <>
              <Typography className="tw-mb-1">
                Ingresa los datos de tus nuevos medios de contacto:
              </Typography>

              <div className="md:tw-flex tw-justify-between tw-mb-1 md:tw-md-2">
                <div
                  className="md:tw-w-1/2"
                  style={{ position: 'relative', 'margin-right': '1%' }}
                >
                  <div style={{ position: 'relative' }}>
                    <span
                      style={{
                        position: 'absolute',
                        top: '1%',
                        'font-size': '14px',
                        'margin-left': '3%',
                        color: focusEmail ? '#FF4F00' : '#696158',
                      }}
                      className="m-0"
                    >
                      {focusEmail
                        ? patchEmail?.length > 0
                          ? 'Ingresa tu nuevo correo electrónico'
                          : null
                        : patchEmail?.length > 0 &&
                          'Ingresa tu nuevo correo electrónico'}
                    </span>
                    <Input
                      type="text"
                      label={
                        patchEmail?.length > 0
                          ? false
                          : 'Ingresa tu nuevo correo electrónico'
                      }
                      className="md:tw-mr-1 tw-mb-1 md:tw-mb-0"
                      onChange={(event) => {
                        const emailValue = event.target.value;
                        setPatchEmail(emailValue);
                      }}
                      onFocus={() => setFocusEmail(true)}
                      onBlur={() => {
                        setFocusEmail(false);
                        handleBlur();
                      }}
                      style={{ 'margin-right': '3%' }}
                    />
                    {emailError && (
                      <p
                        style={{ margin: '0', fontSize: '14px', color: 'red' }}
                      >
                        {emailError}
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ position: 'relative' }} className="md:tw-w-1/2 tw-mt-1 md:tw-mt-0">
                  <div style={{ position: 'relative' }}>
                    <span
                      style={{
                        position: 'absolute',
                        top: '1%',
                        'font-size': '14px',
                        'margin-left': '3%',
                        color: focusEmailConfirm ? '#FF4F00' : '#696158',
                      }}
                      className="m-0"
                    >
                      {focusEmailConfirm
                        ? patchEmailConfirm?.length > 0
                          ? 'Confirma tu correo electrónico'
                          : null
                        : patchEmailConfirm?.length > 0 &&
                          'Confirma tu correo electrónico'}
                    </span>
                    <Input
                      type="text"
                      label={
                        patchEmailConfirm?.length > 0
                          ? false
                          : 'Confirma tu correo electrónico'
                      }
                      className="md:tw-ml-1"
                      onChange={(event) => {
                        const emailValue = event.target.value;
                        setPatchEmailConfrim(emailValue);
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onFocus={() => setFocusEmailConfirm(true)}
                      onBlur={() => {
                        setFocusEmailConfirm(false);
                        handleBlurConfirm();
                      }}
                    />
                    {emailConfirmError && (
                      <p
                        style={{ margin: '0', fontSize: '14px', color: 'red' }}
                      >
                        {emailConfirmError}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {documentType === '00' && (
                <div className="md:tw-flex tw-justify-between tw-mb-3 md:tw-w-1/2">
                  <div style={{ position: 'relative', width: '100%' }}>
                    <span
                      style={{
                        position: 'absolute',
                        top: '1%',
                        'font-size': '14px',
                        'margin-left': '3%',
                        color: focusPhoneConfirm ? '#FF4F00' : '#696158',
                      }}
                      className="m-0"
                    >
                      {focusPhoneConfirm
                        ? inputValue?.length > 0
                          ? 'Ingresa tu número de celular'
                          : null
                        : inputValue?.length > 0 &&
                          'Ingresa tu número de celular'}
                    </span>

                    <Input
                      name="numberPhone-input"
                      pattern={/^\d+$/}
                      type="text"
                      label={
                        inputValue.length > 0
                          ? false
                          : 'Ingresa tu número de celular'
                      }
                      maxLength={9}
                      onChange={(event) => {
                        const value = event.target.value;
                        setInputValue(value);
                      }}
                      onFocus={() => setFocusPhoneConfirm(true)}
                      onBlur={() => {
                        setFocusPhoneConfirm(false);
                        handleBlurPhone();
                      }}
                      onKeyPress={(event) => {
                        const keyCode = event.which
                          ? event.which
                          : event.keyCode;
                        const keyValue = String.fromCharCode(keyCode);
                        if (/\D/.test(keyValue)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        const pasteData =
                          event.clipboardData.getData('text/plain');
                        if (/\D/.test(pasteData)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {phoneError && (
                      <p
                        style={{ margin: '0', fontSize: '14px', color: 'red' }}
                      >
                        {phoneError}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {/* ACTUALIZACION DOCUMENTO IDENTIDAD EXTRANJERO */}
      </MainContainer>

      <Container className="sm:tw-flex tw-justify-end">
        <>
          {documentType === '00' && ( // CON DNI
            <StyledButton
              onClick={
                isUpdateDataContact.firstCheck
                  ? () => {
                      handleNextStep();
                      GTMEvents.BtnOptionsStepOne("Siguiente")
                    }
                  : () => {
                      handleFinish();
                      GTMEvents.BtnOptionsStepOne("Finalizar")
                    }
              }
              disabled={
                disabledButton ||
                (!isFormFilled && !isUpdateDataContact.secondCheck)
              }
            >
              {isUpdateDataContact.firstCheck ? 'Siguiente' : 'Finalizar'}
            </StyledButton>
          )}
          {documentType !== '00' && ( // CON CE / PASAPORTE
            <StyledButton
              onClick={
                isUpdateDataContact.firstCheck
                  ? () => {
                      handleNextStep();
                      GTMEvents.BtnOptionsStepOne("Siguiente")
                    }
                  : () => {
                      handleFinish();
                      GTMEvents.BtnOptionsStepOne("Finalizar")
                    }
              }
              disabled={
                disabledButton ||
                (!isFormFilled && !isUpdateDataContact.secondCheck)
              }
            >
              {isUpdateDataContact.firstCheck ? 'Siguiente' : 'Finalizar'}
            </StyledButton>
          )}
        </>
      </Container>
    </>
  );
}
