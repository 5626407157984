import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { path } from 'ramda';
import { PATH_BASES } from '../../../../shared/constants';

const verifyRedirectFromByPassHOC = (Component) => {
  return ({ ...props }) => {

    const location = useLocation();

    const fromBypassForeignValidation = path(['state', 'fromBypassForeignValidation'], location);

    if(fromBypassForeignValidation) return <Component {...props} />;

    return <Redirect to={PATH_BASES.DATA_UPDATE}/>;
  };
};

export default verifyRedirectFromByPassHOC;
