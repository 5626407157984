import React, { useRef, useState } from 'react';
import { MainTitle } from '../../../shared/components/MainTitle';
import { Text } from '../../../shared/components/Text';
import { MainContainer } from '../../components/MainContainer';
import { StyledButton } from '../../components/Button/styles';
import Logo from '../../../shared/assets/images/prima-afp-logo-orange.svg';
import {
  ErrorMessage,
  ErrorMessageWrapper,
  FormContainerHome,
  InputSelectWrapper,
  StyledText,
  FormContainer,
  TextStyled,
  NavStyledHome
} from '../styles';
import {
  Input,
  Option,
  Select,
  Progress,
} from '@prima-afp/prima-ui-elements/dist/core';
import { useTypesDocument } from '../../hooks/useTypesDocument';
import { useValidateDocument } from '../../hooks/useValidateDocument';

import ReCAPTCHA from 'react-google-recaptcha';
import { ErrorModal } from 'modules/shared/components/Error';
import { GTMEvents } from '../../../shared/utils/GTMevents';

export default function FormPage() {
  const [authHeaders, setAuthHeaders] = useState(JSON.parse(localStorage.getItem("authToken")));
  const [identificationDocumentType, setIdentificationDocumentType] =
    useState('00');
  const [identificationDocumentNumber, setIdentificationDocumentNumber] =
    useState('');
  const [maxLength, setMaxLength] = useState(8);


  const { data: typesDocumentQuery } = useTypesDocument(authHeaders);
  const {
    mutate,
    isError,
    isLoading = false,
    error,
    errorMessage,
    isSuccess,
    handleCloseModal,
    modalSettings,
  } = useValidateDocument();

  const [isInputModified, setIsInputModified] = useState(false);

  const reCaptchaRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isError) {
      await reCaptchaRef.current.reset();
    }
    const token = await reCaptchaRef.current.executeAsync();
    await mutate({ body:{
      documentType: identificationDocumentType,
      documentNumber: identificationDocumentNumber,
      recaptchaToken: token,
    }, token: authHeaders});
  };

  const CustomSelect = ({ className, ...props }) => {
    return (
      <Select
        {...props}
        className={`${className} tipoDocumento-select`}
        dropdownClassName="tipoDocumento-dropdown"
      />
    );
  };

  return (
      <>
        <MainContainer>
          <NavStyledHome>
            <div className="tw-flex tw-flex-wrap tw-h-full tw-justify-between tw-content-center">
                <img src={Logo} alt="logo PRIMA" />
                <TextStyled>Ayer. Hoy. Siempre</TextStyled>
            </div>
          </NavStyledHome>
          <div className="tw-mt-2 tw-mt-0@lg">
            <MainTitle>Actualiza tus medios de contacto</MainTitle>
            <Text className="tw-mt-2">
              Por tu seguridad, realizaremos una validación facial y una vía
              SMS. Te recomendamos tener a la mano una computadora con cámara y
              tu celular.
            </Text>
            <StyledText className="tw-mt-1 tw-mb-2">
              Ingresa tu documento de identidad para identificarte:
            </StyledText>
          </div>
          <div className="tw-text-center tw-mt-2">
            <form onSubmit={handleSubmit} noValidate>
              <FormContainerHome className="tipoDocumento">
                <InputSelectWrapper>
                  <CustomSelect
                    className="tipoDocumento-select"
                    multiSelect={false}
                    label={''}
                    value={identificationDocumentType}
                    onChange={(val) => {
                      setIdentificationDocumentType(val);
                      if (val === '00') {
                        setMaxLength(8);
                      } else if (val === '01') {
                        setMaxLength(13);
                      } else if (val === '04') {
                        setMaxLength(12);
                      } else {
                        setMaxLength(9);
                      }
                      localStorage.setItem(
                        'typeDocument',
                        JSON.stringify(
                          typesDocumentQuery?.documentTypeList?.filter(
                            (document) => document.code === val
                          )
                        )
                      );
                    }}
                  >
                    {typesDocumentQuery?.documentTypeList
                      ?.filter((document) =>
                        typesDocumentQuery?.documentTypeListAllowed?.includes(
                          document.code
                        )
                      )
                      .map((document) => {
                        return (
                          <Option value={document.code} key={document.code}>
                            {document.description}
                          </Option>
                        );
                      })}
                  </CustomSelect>
                  <Input
                    name="numberOfDocument-input"
                    className="tipoDocumento-input"
                    pattern={/^\d+$/}
                    type="text"
                    placeholder="Ingrese el número"
                    maxLength={maxLength}
                    onChange={(event) => {
                      setIdentificationDocumentNumber(event.target.value);
                      setIsInputModified(true)
                    }}
                    onKeyPress={(event) => {
                      const keyCode = event.which ? event.which : event.keyCode;
                      const keyValue = String.fromCharCode(keyCode);
                      if (identificationDocumentType === '00') {
                        if (/\D/.test(keyValue)) {
                          event.preventDefault();
                        }
                      } else {
                        if (!/^[a-zA-Z0-9]+$/.test(keyValue)) {
                          event.preventDefault();
                        }
                      }
                    }}
                                       
                    onPaste={(event) => {
                      const pasteData = event.clipboardData.getData('text/plain');
                      if (identificationDocumentType === '00') {
                        if (/\D/.test(pasteData)) {
                          event.preventDefault();
                        }
                      } else {
                        if (!/^[a-zA-Z0-9]+$/.test(pasteData)) {
                          event.preventDefault();
                        }
                      }
                    }}
                    
                  />
                </InputSelectWrapper>
                <ErrorMessageWrapper>
                  {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                </ErrorMessageWrapper>
              </FormContainerHome>
              <FormContainer>
                <StyledButton
                  onClick={GTMEvents.BtnValidateDocument}
                  className="tw-flex tw-w-full button-wrap-valid tw-relative"
                  type="submit"
                  disabled={
                    (identificationDocumentType === '00' &&
                      identificationDocumentNumber.length < 8) ||
                    ((identificationDocumentType === '01' ||
                      identificationDocumentType === '04' ||
                      identificationDocumentType == '06') &&
                      identificationDocumentNumber.length < 4) ||
                    (identificationDocumentType !== '00' &&
                      identificationDocumentType !== '04' &&
                      identificationDocumentType !== '01' &&
                      identificationDocumentType !== '06' &&
                      identificationDocumentNumber.length < 9)
                  }
                  
                >
                  {isLoading && (
                    <span className={'progress-inline'}>
                      <Progress theme={'white'} showMessages={false} />
                    </span>
                  )}
                  <span>
                    {isLoading
                      ? `${'Validando'}`
                      : isInputModified
                      ? `${'Validar'}`
                      : isSuccess
                      ? `${'Validado'}`
                      : error
                      ? `${'Validado'}`
                      : `${'Validar'}`}
                  </span>
                </StyledButton>
              </FormContainer>
            </form>
          </div>
          <ErrorModal closeModal={handleCloseModal} settings={modalSettings} />
        </MainContainer>
        <ReCAPTCHA
          ref={reCaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
          size="invisible"
        />
      </>
  );
}
