import { useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { validateDocument } from '../services';
import { useHistory } from 'react-router-dom';
const postValidateDocument = async ({body, token}) => {
  if(!token){
    return;
  }
  const res = await validateDocument(body, token);
  const data = await res;
  return data;
};
const redirectToForm = () => {
    window.location.href = '/actualizacion-datos-biometria/form';
  };

export const useValidateDocument = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isErrorModalActive, setIsErrorModalActive] = useState(false);

  const { mutate, isLoading, error, data, isSuccess, isError } = useMutation(
    postValidateDocument,
    {
      onError: (error) => {
        if (error.message === 'user has not been found') {
          setErrorMessage(
            'No hemos podido identificarte como nuestro afiliado. Inténtalo nuevamente.'
          );
          setIsErrorModalActive(true);
        } else {
          setErrorMessage(
            'Lo sentimos, estamos trabajando para solucionar el inconveniente.'
          );
          setIsErrorModalActive(true);
        }
      },
      onSuccess: (data) => {
        setErrorMessage(null);
        const { userWebDataDto, iscr } = data;
        const splitName = userWebDataDto?.firstName?.trimEnd()?.split(' ');
        const name = splitName.length > 1 ? `${splitName[0]} ${splitName[1]}` : splitName[0];
        queryClient.setQueryData('validateDocument', {
          ...data,
          userWebDataDto: {
            documentNumber: userWebDataDto?.documentNumber,
            documentType: userWebDataDto?.documentType,
            name: name,
            lastName: `${userWebDataDto?.lastName?.trim()} ${userWebDataDto?.sureName?.trim()}`,
            email: userWebDataDto?.email,
            phoneNumber: userWebDataDto?.phoneNumber,
          },
        });
        localStorage.setItem(
          'validateDocument',
          JSON.stringify({
            ...data,
            userWebDataDto: {
              documentNumber: userWebDataDto?.documentNumber,
              documentType: userWebDataDto?.documentType,
              name: name,
              lastName: `${userWebDataDto?.lastName?.trim()} ${userWebDataDto?.sureName?.trim()}`,
              email: userWebDataDto?.email,
              phoneNumber: userWebDataDto?.phoneNumber,
            },
          })
        );
        if (iscr) {
          redirectToForm();
        } else {
          setIsErrorModalActive(true);
        }
      },
    }
  );

  const handleCloseModal = () => {
    setIsErrorModalActive(false);
  };

  return {
    mutate,
    isLoading,
    isError,
    error,
    errorMessage,
    data,
    isSuccess,
    handleCloseModal,
    modalSettings: {
      type: 'alert',
      isActive: isErrorModalActive,
      title: 'Error',
      content: errorMessage,
      closeModal: handleCloseModal,
    },
  };
};
