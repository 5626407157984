import PropTypes from "prop-types";
import React from "react";

import { Progress } from "@prima-afp/prima-ui-elements/dist/core";

import { StyledModal } from "./styles";

export default function Loading(props) {

    const { show } = props;

    return (
        <StyledModal show={show}>
            <Progress showMessages={false}></Progress>
        </StyledModal>
    );
}

Loading.propTypes = {
    show: PropTypes.any
};
