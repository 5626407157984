import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import PublicRouter from './PublicRouter';

const ApplicationRouter = ({ publicModules = [] }) => {

  return (
    <BrowserRouter>
      <PublicRouter publicModules={ publicModules } />
    </BrowserRouter>
  );
};

export default ApplicationRouter;