import React from "react";
import { Button } from "@prima-afp/prima-ui-elements/dist/core";
import alertNaranja from "../../assets/images/alertNaranja.svg";
import { Container } from "@prima-afp/prima-ui-elements/dist/layouts";
import { useHistory } from 'react-router-dom';

const MaxAttempts = () => {

  const history = useHistory();

  const handleContinue = () => {
    history.push('/actualizacion-datos-biometria');
  };
  

  return (
    <>
    <Container>
      <div style={{width: '100%', textAlign: 'center'}}>
        <img
          src={alertNaranja}
          alt="respaldo"
          width={50}
          style={{ marginTop: "30px" }}
        ></img>

        <h2
          style={{
            color: "#696158",
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "20px",
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: 600,
            maxWidth: 380,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <span>
            Has superado el número de intentos para
            <br></br> validar tu identidad.
          </span>
        </h2>
        <p
          id="textInformation"
          className="text"
          style={{ textAlign: "center" }}
        >
          También puedes comunicarte a:
        </p>
        <p
          style={{ textAlign: "center" }}
          >
          Fono Prima Lima 615 - 7272
        </p>
        <p
          style={{ textAlign: "center" }}
          >
          Fono Prima Provincias 0 - 801 - 18010
        </p>

        <Button
          hierarchy={"primary"}
          size={"large"}
          onClick={handleContinue}
          style={{ marginTop: "25px", width: "60%" }}
        >
          Entendido
        </Button>
      </div>
    </Container>
    </>
  );
};

export default MaxAttempts;
