import { useQuery } from 'react-query';
import { getDocuments } from '../services';

const getTypesDocument = async (token) => {
    if (!token) {
        return; 
    }
    const res = await getDocuments(token);
    const data = await res;
    return data;
}  

export const useTypesDocument = (token) =>{
    const typesDocumentQuery = useQuery(
        ['typeDocuments'],
        ()=>getTypesDocument(token),
        { enabled: !!token,
          placeholderData: {
            "maxFileSize": "8",
            "documentTypeList": [
                {
                    "code": "00",
                    "description": "D.N.I."
                },
                {
                    "code": "01",
                    "description": "Carnet Extranjeria"
                },
                {
                    "code": "04",
                    "description": "Pasaporte"
                },
                {
                    "code": "06",
                    "description": "Carnet del Permiso Temporal de Permanencia"
                },
            ],
            "documentTypeListAllowed": [
                "00",
                "01",
                "04",
                "06"
            ]
            },
          staleTime: 1000 * 60 * 60
        }
    );
    return typesDocumentQuery;
}

