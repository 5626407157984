export const CONTENT_CLIENT_PRIMA =
  "Para mayor información llámanos al 615-7272 en Lima o al 0-801-18010 en provincias";

export const PATH_BASES = {
  DATA_UPDATE: "/actualizacion-datos-biometria",
};

const accessKey = {
  client_secret: `${process.env.REACT_APP_TOKEN_CLIENT_SECRET}`,
  client_id: `${process.env.REACT_APP_TOKEN_CLIENT_ID}`,
  scope: `${process.env.REACT_APP_TOKEN_SCOPE}`,
};

export const BODY_REQUEST_GENERATION = Object.keys(accessKey)
  .map(
    (key) => encodeURIComponent(key) + "=" + encodeURIComponent(accessKey[key])
  )
  .join("&");

export const HEADER_REQUEST_GENERATION = {
  "Content-Type": "application/x-www-form-urlencoded",
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBSCRIPTION_KEY_OAUTH,
};
