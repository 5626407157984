import React, { useState } from 'react';
import { format } from 'date-fns';
import { useLocation, useHistory } from 'react-router-dom';
import { path } from 'ramda';
import { Alert, Button, DateInput, Text } from '@prima-afp/prima-ui-elements/dist/core';
import { Container } from '@prima-afp/prima-ui-elements/dist/layouts';
import chica from '../../assets/images/chica.svg';
import alertNaranja from '../../assets/images/alertNaranja.svg';
import { saveDataForeign } from '../../../../modules/biometrics-data-update-new/services/index';
import { verifyRedirectFromByPassHOC } from './hocs';

function AdditionalValidationForeigners() {

  const [date, setDate] = useState(null);
  const [errorDate, setErrorDate] = useState(false);
  const [bioAttempt, setBioAttempt] = useState();
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
  };

  const handleValidation = async () => {
    setIsLoading(true);
    const headers = JSON.parse(localStorage.getItem('authToken'));
    const validateDocumentData = JSON.parse(localStorage.getItem('validateDocument'));
    const validateDocumentEmail = JSON.parse(localStorage.getItem('userData'));

    const documentType = path(['userWebDataDto', 'documentType'], validateDocumentData);
    const documentNumber = path(['userWebDataDto', 'documentNumber'], validateDocumentData);
    const email = path(['patchEmail'], validateDocumentEmail);
    const idBiometric = path(['state', 'requestIdValidation'], location);

    const saveDataBody = {
      documentType,
      documentNumber,
      email,
      idBiometric,
      validationType: '5',
      birthdate: format(date, 'yyyy-MM-dd'),
    };

    try {
      const response = await saveDataForeign(saveDataBody, headers);
    
      if (response && response.code === "0" && response.attemptsFailed === 3) {
        history.push('/actualizacion-datos-biometria/maximos-intentos');
      }
    
      if (response && response.attemptsFailed !== undefined && response.attemptsFailed > 0) {
        setErrorDate(true); 
        setBioAttempt(response.attemptsFailed);
      }
    
      if (response && response.code === "1") {
        history.push('/actualizacion-datos-biometria/finished');
      }
    
      if (!response || (response.code !== "0" && response.code !== "1")) {
        console.error('Error inesperado en la respuesta:', response);
      }
    
      setDate(null);
    } catch (errorDate) {
      const errorMessage = errorDate.toString();
      if (errorMessage.includes("attempts limit exceeded")) {
        history.push('/actualizacion-datos-biometria/maximos-intentos');
      } else {
        console.error('Error al enviar datos:', errorDate);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      type="full"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        alignItems: 'center',
      }}
    >
      <div style={{ padding: '0', textAlign: 'center' }}>
        {errorDate ? (
          <img
            src={alertNaranja}
            alt="alert"
            width={50}
            style={{ marginTop: '30px', marginBottom: '10px' }}
          />
        ) : (
          <img
            src={chica}
            alt="alert"
            width={80}
            style={{ marginTop: '20px', marginBottom: '10px' }}
          />
        )}
      </div>
      <Text bold="600" center color="#696158" style={{ fontSize: '20px', margin: '0px 20px' }}>
        {errorDate ? "No hemos podido validar tu identidad" : "Tenemos problemas validando tu identidad"}
      </Text>
      <p
        style={{
          textAlign: 'center',
          marginLeft: '20px',
          marginRight: '20px',
          fontSize: '16px',
          color: '#696158',
        }}
      >
        {errorDate
          ? <>La respuesta que ingresaste fue incorrecta, por favor vuelve a <br /> intentarlo para continuar con la actualización.</>
          : <>Por favor, contesta la siguiente pregunta para continuar con la <br /> actualización de datos:</>}
      </p>
      <Text bold="600" center color="#696158" style={{ fontSize: '16px' }} >
        ¿Cuál es tu fecha de nacimiento?
      </Text>
      <div className={'tw-w-full md:tw-w-1/3 tw-px-2 tw-mb-1'} style={{ width: '100%', maxWidth: '413px' }}>
        <DateInput
          theme="lightOrange"
          label="Ingresa tu respuesta"
          date={date}
          onChange={handleDateChange}
          name="fechaInicio"
        />
      </div>
      {errorDate && (
        <div className={'tw-w-full md:tw-w-1/3 tw-px-2 tw-mb-1'} style={{ width: '100%', maxWidth: '413px' }}>
          <Alert
            type="info"
            className="alertContainer"
            children={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                <p className="textInfo">{`Te quedan ${3 - bioAttempt} intentos`}</p>
                </div>
              </>
            }
          />
        </div>
      )}
      <div className={'tw-w-full md:tw-w-1/3 tw-px-2 tw-mb-1'} style={{ width: '100%', maxWidth: '413px' }}>
        <Button
          style={{
            padding: '14px 12px',
            marginBottom: '10px',
            width: '100%',
          }}
          onClick={handleValidation}
          disabled={isLoading || date === null}
          >
          {isLoading ? 'Validando...' : 'Validar y continuar'}
        </Button>
      </div>

    </Container>
  );
}

export default verifyRedirectFromByPassHOC(AdditionalValidationForeigners);

