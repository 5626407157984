import React, { useEffect } from 'react';
import { StyledButton } from '../../components/Button/styles';
import { MainContainer } from '../../components/MainContainer';
import SolicitudFinalizada from '../../../shared/assets/images/solicitud_finalizada.svg';
import { Navbar } from '../../components/Navbar';
import { GTMEvents } from '../../../shared/utils/GTMevents';

export default function Finished() {
  const otpValue =  JSON.parse(localStorage.getItem('otpVerifycate'));

  useEffect(() => {
    GTMEvents.RenderFinalStep()
  }, [])

  return (
    <>
      <MainContainer>
        <Navbar back={false} />
        <div className='tw-text-left tw-mb-2'>
          <div className='tw-mb-2'>
            <img src={SolicitudFinalizada} alt='' />
          </div>
          <div className='tw-mb-2 tw-font-bold tw-text-primary tw-text-3xl' style={{color: 'var(--greenColor)'}}>
            ¡Listo {otpValue?.name}!
          </div>
          <p className='tw-mb-1'>
            <strong>Hemos registrado con éxito la actualización de tus datos personales</strong>
          </p>
          <p>
            En 5 minutos podrás visualizar tus datos actualizados en tu cuenta Prima.
          </p>
        </div>
        <div className='tw-text-left'>
          <a href='https://miespacio.prima.com.pe/'>
            <StyledButton>Ir a la agencia virtual</StyledButton>
          </a>
        </div>
      </MainContainer>
    </>

  );
}
